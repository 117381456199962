import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {EcommerceService} from '../../../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {UiCommonService} from '../../../service/uiCommon.service';
import * as moment from 'moment';
import {SharedService} from "../../../service/shared.service";
import {CategoryTypes} from "../../../../core/models";
import {ConfigData} from "../../../../modules/integration/ecommerce/pages/configuration/data";
import {
    OrdereasyConfigService
} from '../../../../modules/integration/ecommerce/pages/configuration/ordereasy-config/ordereasy-config.service';
import Swal from 'sweetalert2';
import {StoreProfileDetailsService} from '../../../../core/services/store-profile-details.service';
import {LoginService} from '../../../../modules/login/login.service';
import {ImageCompressService} from "../../../../core/services/image-compress.service";
import { DateAdapter } from '@angular/material/core';

declare var google;

@Component({
    selector: 'app-ordereasy-store-profile',
    templateUrl: './ordereasy-store-profile.component.html',
    styleUrls: ['./ordereasy-store-profile.component.scss']
})
export class OrdereasyStoreProfileComponent implements OnInit {
    storeTiming = [{id: 'Fixed', name: 'Fixed time'}, {id: 'Split', name: 'Split working hours'}];
    selectedStoreTiming = 'Fixed';
    socialMediaModal = false;
    @Input() selectedoutletId = 0;
    @Input() isEditable = true;
    @Output() shopName = new EventEmitter();
    @Input() storeUserId: any;
    showLocationError = false;
    defaultImageUrl = './assets/images/uploadImg.svg';
    selectedDays = [];
    addedSplitTime = [];
    samples = ['order count' , 'order creation']      ;
    stdCodes = {
        state: {
            value: '',
            isOpen: false,
        },
        stateList: [],
        city: {
            value: '',
            isOpen: false
        },
        cityList: [],
        stdCode: ''
    };
    orderFulfilment = {
        value: 'Self Shipping',
        isOpen: false,
        list: ['Self Shipping', 'Logistics Shipping']
    };
    isPanServiceable = {
        value: 'Pan India delivery',
        isOpen: false,
        list: ['Pan India delivery', 'Delivery in Radius']
    };
    outletList = {
        value: '',
        isOpen: false,
        list: []
    };
    verticalList = {
        value: '',
        isOpen: false,
        list: []
    };
    languageType = {
        value: '',
        isOpen: false,
        list: [
            { id: 1, code: 'en', name: 'English' },
            { id: 2, code: 'tam', name: 'Tamil' },
            { id: 3, code: 'guj', name: 'Gujarathi' }
        ]
    };
    productList = {
        value: '',
        isOpen: false,
        list: []
    };
    vertical = {
        value: 'SUPER-MARKET',
        isOpen: false,
        list: ['SUPER-MARKET', 'PHARMACY', 'RESTAURANT']
    };
    logisticsDeliveryType = {
        value: 'Standard Delivery',
        isOpen: false,
        list: ['Standard Delivery', 'Immediate Delivery', 'Express Delivery']
    };
    standardDeliveryType = {
        value: 'Same Day Delivery',
        isOpen: false,
        list: ['Same Day Delivery', 'Next Day Delivery', 'Both']
    };
    socialMediaType = {
        value: 'Facebook',
        isOpen: false,
        list: ['FACEBOOK', 'WHATSAPP', 'INSTAGRAM', 'NONE']
    };
    socialMediaLink = '';
    currency = {
        value: ' ₹',
        isOpen: false,
        list: [{name: ' ₹', id: '₹'}, {name: ' $', id: '$'} ]
    };
    workingHours24Format = {
        value: '01.00',
        isOpen: false,
        list: [
            { id: 0, shopOpensAt: '00:00', value: 0 },
            { id: 0, shopOpensAt: '01:00', value: 1 },
            { id: 0, shopOpensAt: '02:00', value: 2 },
            { id: 0, shopOpensAt: '03:00', value: 3 },
            { id: 0, shopOpensAt: '04:00', value: 4 },
            { id: 0, shopOpensAt: '05:00', value: 5 },
            { id: 0, shopOpensAt: '06:00', value: 6 },
            { id: 0, shopOpensAt: '07:00', value: 7 },
            { id: 0, shopOpensAt: '08:00', value: 8 },
            { id: 0, shopOpensAt: '09:00', value: 9 },
            { id: 0, shopOpensAt: '10:00', value: 10 },
            { id: 0, shopOpensAt: '11:00', value: 11},
            { id: 0, shopOpensAt: '12:00', value: 12 },
            { id: 0, shopOpensAt: '13:00', value: 13 },
            { id: 0, shopOpensAt: '14:00', value: 14 },
            { id: 0, shopOpensAt: '15:00', value: 15 },
            { id: 0, shopOpensAt: '16:00', value: 16 },
            { id: 0, shopOpensAt: '17:00', value: 17 },
            { id: 0, shopOpensAt: '18:00', value: 18 },
            { id: 0, shopOpensAt: '19:00', value: 19 },
            { id: 0, shopOpensAt: '20:00', value: 20 },
            { id: 0, shopOpensAt: '21:00', value: 21 },
            { id: 0, shopOpensAt: '22:00', value: 22 },
            { id: 0, shopOpensAt: '23:00', value: 23 }
        ]
    };
    timeList = [];
    applyForAll = false;
    selectedDay = {
        shopId: 0,
        outletId: 0,
        companyId: '1',
        day: '',
        shopOpensAt: '00:00',
        shopClosesAt: '00:00',
        shopClosesAt12hrs: '00:00 PM',
        storeAvailability: '',
        shopOpensAt12hrs: '00:00 AM'
    };
    isLatitudeCopied = false;
    isLongitudeCopied = false;
    startTime: '00';
    endTime: '00';
    days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    private map: any;
    isDaysModalOpen = false;
    copied = 'Copied!';
    private geoCoder;
    @ViewChild('search', {static: false}) public searchElementRef: ElementRef;
    zoom: number;
    selectedCity: any;
    previousSocialMedia = [];
    storeProfileDetails = {
        'userId': '',
        'posUrl': this.authService.isStandalone() ? 'StandAlone' : '',
        'posId': '',
        'availability': 'Active',
        'skewCode': '',
        'apiUser': 'OrderEasy',
        'otp': '',
        'vendor': '',
        'baseProduct': '',
        'domain': '',
        'orderNo': '',
        'reqTimeout': '',
        'outletId': 1,
        'outletName': '',
        'userName': '',
        'customerId': '',
        'shopName': '',
        'shopImageUrl': '',
        'organization': [],
        'vertical': 'SUPER-MARKET',
        'latitude': 0,
        'longitude': 0,
        'currency': '',
        'languageType': '',
        'posLocationId': 0,
        'locationName': '',
        'shopNumber': '',
        'panNumber': '',
        'panAttachments': [],
        'fssaiNumber': '',
        'fssaiAttachments': [],
        'gstNumber': '',
        'gstAttachments': [],
        'chequeAttachments': [],
        'bankName': '',
        'accountName': '',
        'accountNo': 0,
        'ifscCode': '',
        'chequeNo': '',
        'doorNo': '',
        'buildingName': '',
        'streetName': '',
        'city': '',
        'locality': '',
        'district': '',
        'state': '',
        'country': '',
        'pinCode': '',
        'stdCode': '',
        'contactName': '',
        'contactPhone': '',
        'contactMobile': '',
        'contactEmail': '',
        'deliveryDays': '',
        'orderFulfilment': this.orderFulfilment.value,
        'deliveryType' : '',
        'description': '',
        'longDescription': '',
        'socialMedia': [],
        'shopOpensAt': '00:00',
        'shopClosesAt': '00:00',
        'isFixedTiming': true,
        'servicableFrequency': '',
        'splitTime': [],
        'availabilityDays': [],
        'closingDays': [],
        'radius': 0,
        'timeFormat': 'days:hrs:min',
        'categoryType': '',
        'isPanServiceable' : true
    };
    verticalDetails = [];
    defaultProfileDetails: any;
    isONDC = false;
    isPOS = false;
    isOrderEasyStandalone = false;
    isMarketPlace = false;
    imageModal = false;
    selectedImage: any;
    isEditSocialMedia = false;
    categoryTypes: CategoryTypes[] = ConfigData.urbanpiper.categoryTypes ;
    outletDetails: any;
    locationList: any;
    isStandAloneOndc = false;
    isOEOndcStandAlone = false;
    checkOndc = false;
    isThirdParty = false;
    defaultLocation = {
        value: 'Main Location',
        isOpen: false,
        list: [{
            'locationId': 1,
            'locationName': 'Main Location'
        }]
    };
    timeToShip = {
        days: '',
        hours: '',
        minutes: '',
    };
    durationObj = {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    timeToShipIsoFormat = '';
    selectedDate = '';
    isFoodAggregatorStore = false;
    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ecommerceService: EcommerceService,
        private notifyService: NotificationService,
        private authService: AuthenticationService,
        private uiCommonService: UiCommonService,
        private imageCompressService: ImageCompressService,
        private dateAdapter: DateAdapter<Date>,
        private readonly sharedService: SharedService,
        private ordereasyService: OrdereasyConfigService,
        private loginService: LoginService,
        public storeProfileDetailsService: StoreProfileDetailsService
    ) {
        this.dateAdapter.setLocale('en-GB');
    }

    ngOnInit() {
        this.isThirdParty = this.authService.getMultiAddonDetails('935') ||  this.authService.getMultiAddonDetails('936') ;
        this.getStoreDetails();
        this.getStdCodes();
        this.timeList = [...this.workingHours24Format.list];
        this.isOrderEasyStandalone = (this.authService.getSkewCode() === '917');
        this.isPOS = (this.authService.getSkewCode() === '742' || this.authService.getSkewCode() === '926');
        this.isONDC = this.authService.getSkewCode() === '923';
        this.isStandAloneOndc = this.authService.getSkewCode() === '917' && this.authService.getMultiAddonDetails('923');
        this.isOEOndcStandAlone = this.authService.getSkewCode() === '742' && this.authService.getMultiAddonDetails('923');
        this.checkOndc = this.isONDC || this.isStandAloneOndc || this.isOEOndcStandAlone;
        this.isThirdParty = this.authService.getMultiAddonDetails('935') ||  this.authService.getMultiAddonDetails('936') ;
        this.isMarketPlace = this.authService.isMarketPlace();
        this.isFoodAggregatorStore = this.authService.getSkewCode() === '938';
        if (this.checkOndc || this.isThirdParty) {
            const index = this.vertical.list.indexOf('PHARMACY');
            if (index > -1) {
                this.vertical.list.splice(index, 1);
            }
        }

        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
            if (this.isEditable) {
                const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
                autocomplete.addListener('place_changed', () => {
                    // @ts-ignore
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    this.storeProfileDetails.latitude = place.geometry.location.lat();
                    this.storeProfileDetails.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                });
            }
        });
        this.ordereasyService.getProduct().subscribe(
            products => {
                this.productList.list = [...products];
            },
            (err) => {
                this.notifyService.showError('Error', 'Error while fetching details');
            }
        );
        if (this.isPOS) {
            this.ordereasyService.getOutletDetails().subscribe(
                outlets => {
                    this.authService.setCustomerId(outlets[0].customerId);
                    this.outletDetails = outlets;
                    if (this.outletDetails.length === 0 && this.authService.getSkewCode() === '742') {
                        (Swal as any).fire('Oops..!', 'Unable to list the outlet details, Kindly ensure the below two check lists. \n 1. Kindly check whether Mail Id - ' + this.authService.getEmailId() + ' is mapped correctly with the respective CustomerId. \n 2. Kindly check whether the outlet is enabled for online applicable.! \n 3. Kindly check whether your POS license expired.! ',
                            'error');
                    }
                    for (let i = 0; i < this.outletDetails.length; i++) {
                        this.outletDetails[i].outletId = this.outletDetails[i].outletId !== undefined ? this.outletDetails[i].outletId : (this.storeProfileDetails.outletId ? this.storeProfileDetails.outletId : 1);
                    }
                    this.outletList.list = [...this.outletDetails];
                    if (this.outletList.list[0].companyDetails && this.outletList.list[0].companyDetails.length) {
                        this.verticalList.list = this.outletList.list[0].companyDetails;
                    }
                },
                (error) => {
                    this.notifyService.showError('Error', error);
                },
                () => {
                }
            );
            this.getOutletList();
        }
        // this.scheduleFrequencyHour = this.workingHours24Format.list;
    }

    public setStoreUserId(userId) {
        this.storeUserId = userId;
    }

    getStdCodes() {
        const body = {
            'userId': this.authService.getServiceUserId(),
            'vendor': this.authService.getSkewName()
        };
        this.ecommerceService.getStdCodes(body).subscribe(
            res => {
                if (res) {
                    this.stdCodes.stateList = res;
                }
            },
            error => {
                this.notifyService.showWarn('Warn', error);
            }
        );
    }

    getOutletList() {
        const formObj = {
            'integrationAccountId': this.authService.getServiceUserId(),
            'roleId': this.authService.getRoleId(),
            'skewCode': this.authService.getSkewCode(),
            'vendor': this.authService.getSkewName(),
            'authId': this.authService.getAuthId(),
            'authEmpId': this.authService.getAuthEmpId(),
        };

        this.loginService.getOutletList(formObj).subscribe(
            (outlets) => {
                if (outlets && outlets.length > 0) {
                    if (outlets[0].location && outlets[0].location.length > 0) {
                        this.locationList = outlets[0].location;
                        this.storeProfileDetails.posLocationId = 1;
                    } else {
                        this.locationList = Object.assign([], this.defaultLocation.list);
                        this.storeProfileDetails.posLocationId = 1;
                    }
                } else {
                    this.locationList = Object.assign([], this.defaultLocation.list);
                    this.storeProfileDetails.posLocationId = 1;
                }
                this.defaultProfileDetails = {...this.storeProfileDetails};
            },
            (error) => {
                this.notifyService.showError('Error', error);
            }

        );
    }

    filterName(LocationID) {
        const filteredName: any = this.locationList.filter(u => u.locationId == LocationID);
        if (filteredName && filteredName.length > 0) {
            return (filteredName[0].locationName);
        } else {
            return '';
        }

    }

    private setCurrentLocation() {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                    if (!this.storeProfileDetails.latitude && !this.storeProfileDetails.longitude) {
                        this.storeProfileDetails.latitude = position.coords.latitude;
                        this.storeProfileDetails.longitude = position.coords.longitude;
                    }
                    this.zoom = 8;
                    this.getAddress(this.storeProfileDetails.latitude, this.storeProfileDetails.longitude);
                },
                error => {
                    switch (error.code) {
                        case 1:
                            this.showLocationError = true;
                            break;
                        case 2:
                            break;
                        case 3:
                            break;
                    }
                });
        }
    }

    markerDragEnd($event: MouseEvent) {
        // @ts-ignore
        this.storeProfileDetails.latitude = $event.coords.lat;
        // @ts-ignore
        this.storeProfileDetails.longitude = $event.coords.lng;
        this.getAddress(this.storeProfileDetails.latitude, this.storeProfileDetails.longitude);
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                }
            }
        });
    }

    closeSocialMediaModal() {
        this.socialMediaModal = false;
    }

    openSocialMediaModal(value?) {
        this.socialMediaModal = true;
        this.socialMediaLink = '';
        this.socialMediaType.value = 'FACEBOOK';
        this.isEditSocialMedia = false;
        if (value) {
            this.isEditSocialMedia = true;
            this.socialMediaLink = value.socialMediaLink;
            this.socialMediaType.value = value.socialMediaType;
        }
    }

    openOption(config) {
        config.isOpen = !config.isOpen;
    }

    optionStdCodeChange(config: any, type?: any, value?: any) {
        if (type === 'state') {
            config.value = value.state;
            this.stdCodes.cityList = value.cites;
            this.selectedCity = '';
            this.stdCodes.city.value = '';
        } else if (type === 'city') {
            config.value = value.cityName;
            this.selectedCity = value;
        }
    }

    saveStdCodes() {
        if (!this.stdCodes.state.value) {
            this.notifyService.showWarn('Warn', 'Kindly Select State');
        } else if (!this.stdCodes.city.value) {
            this.notifyService.showWarn('Warn', 'Kindly Select City');
        } else {
            this.storeProfileDetails.stdCode = this.selectedCity.stdCode;
            this.closeModal('isDaysModalOpen');
        }
    }

    optionChange(config, type) {
        if (config === 'currency') {
            this.currency.value = type.name;
            this.storeProfileDetails[config] = encodeURIComponent(type.id);
        } else if (config === 'outletName') {
            this.storeProfileDetails.outletName = type.shopName;
            this.storeProfileDetails.posUrl = type.outletUrl;
            this.storeProfileDetails.outletId = type.outletId;
            this.storeProfileDetails.orderNo = type.orderNo;
        } else if (config === 'isPanServiceable') {
            this[config].value = type;
            this.storeProfileDetails.isPanServiceable =  (type === 'Pan India delivery');
        } else if (config === 'logisticsDeliveryType') {
            this[config].value = type;
        } else if (config === 'standardDeliveryType') {
            this[config].value = type;
        } else if (config === 'languageType') {
            this[config].value = type.name;
            this.storeProfileDetails.languageType = type.code;
        } else if (config === 'locationId') {
            this.storeProfileDetails.locationName = this.filterName(type);
            this.storeProfileDetails.posLocationId = type;
        } else {
            this.storeProfileDetails[config] = type;
        }
    }

    encodeCurrencyValue(config) {
        this.storeProfileDetails[config] = encodeURIComponent(this.currency.value);
    }
    optionSocialChange(config, type) {
        config.value = type;
        if (type.toLowerCase() === 'none') {
            this.socialMediaLink = '';
        }
    }

    onMapReady(e) {
        this.map = e;
    }

    saveSocialMediaLink(value) {
        const index = this.storeProfileDetails.socialMedia.findIndex(prop => prop.socialMediaType === value);
        if (!this.socialMediaLink.trim() && value.toLowerCase() !== 'none') {
            this.notifyService.showWarn('Warn', 'Please enter the social media link');
            return;
        } else if (value.toLowerCase() === 'none') {
            this.storeProfileDetails.socialMedia = [];
        } else if (!this.storeProfileDetails.socialMedia.length && this.socialMediaLink) {
            this.storeProfileDetails.socialMedia.push({
                'id': index + 1,
                'socialMediaType': value,
                'socialMediaLink': this.socialMediaLink
            });
        } else if (this.storeProfileDetails.socialMedia.length && this.socialMediaLink) {
            if (this.isPOS || this.isOrderEasyStandalone) {
                this.storeProfileDetails.socialMedia[0].socialMediaLink = this.socialMediaLink;
                this.storeProfileDetails.socialMedia[0].socialMediaType = this.socialMediaType.value;
            } else if (this.socialMediaLink && index === -1) {
                this.storeProfileDetails.socialMedia.push({
                    'id': index + 1,
                    'socialMediaType': value,
                    'socialMediaLink': this.socialMediaLink
                });
            } else {
                this.storeProfileDetails.socialMedia[index].socialMediaLink = this.socialMediaLink;
            }
        }
        this.closeSocialMediaModal();
    }

    validateProfileDetails() {
        return !((!this.storeProfileDetails.shopName && this.storeProfileDetailsService.mandatoryField('shopName')) || (!this.storeProfileDetails.availability && this.storeProfileDetailsService.mandatoryField('availability')) || (!this.storeProfileDetails.vertical && this.storeProfileDetailsService.mandatoryField('vertical'))
            || (!this.storeProfileDetails.panNumber && this.storeProfileDetailsService.mandatoryField('panNumber')) || (!this.storeProfileDetails.gstNumber && this.storeProfileDetailsService.mandatoryField('gstNumber')) || (!this.storeProfileDetails.fssaiNumber && this.storeProfileDetailsService.mandatoryField('fssaiNumber')) || (!this.storeProfileDetails.shopImageUrl && this.storeProfileDetailsService.mandatoryField('shopImageUrl')));
    }

    validateBankDetails() {
        return !((!this.storeProfileDetails.accountName && this.storeProfileDetailsService.mandatoryField('accountName')) || (!this.storeProfileDetails.bankName && this.storeProfileDetailsService.mandatoryField('bankName')) || (!this.storeProfileDetails.ifscCode && this.storeProfileDetailsService.mandatoryField('ifscCode'))
            || (!this.storeProfileDetails.chequeNo && this.storeProfileDetailsService.mandatoryField('chequeNo')) || (!this.storeProfileDetails.accountNo && this.storeProfileDetailsService.mandatoryField('accountNo')));
    }

    validateAddressDetails() {
        return !((!this.storeProfileDetails.streetName && this.storeProfileDetailsService.mandatoryField('streetName')) || (!this.storeProfileDetails.locality && this.storeProfileDetailsService.mandatoryField('locality')) || (!this.storeProfileDetails.buildingName && this.storeProfileDetailsService.mandatoryField('buildingName')) || (!this.storeProfileDetails.city && this.storeProfileDetailsService.mandatoryField('city')) || (!this.storeProfileDetails.district && this.storeProfileDetailsService.mandatoryField('district'))
            || (!this.storeProfileDetails.state && this.storeProfileDetailsService.mandatoryField('state')) || (!this.storeProfileDetails.country && this.storeProfileDetailsService.mandatoryField('country')) || (!this.storeProfileDetails.pinCode && this.storeProfileDetailsService.mandatoryField('pinCode')) || (!this.storeProfileDetails.stdCode && this.storeProfileDetailsService.mandatoryField('stdCode'))
            || (!this.storeProfileDetails.latitude && this.storeProfileDetailsService.mandatoryField('latitude')) || (!this.storeProfileDetails.longitude && this.storeProfileDetailsService.mandatoryField('longitude')));
    }

    validatePosDetails() {
        return !((!this.storeProfileDetails.outletName && this.storeProfileDetailsService.mandatoryField('outletName')) || (!this.storeProfileDetails.outletId && this.storeProfileDetailsService.mandatoryField('outletId')) || (!this.storeProfileDetails.baseProduct && this.storeProfileDetailsService.mandatoryField('baseProduct'))
            || (!this.storeProfileDetails.posUrl && this.storeProfileDetailsService.mandatoryField('posUrl')) || (!this.storeProfileDetails.posLocationId && this.storeProfileDetailsService.mandatoryField('posLocationId')) ||
            ((this.storeProfileDetails.baseProduct === 'RPOS6' || this.storeProfileDetails.baseProduct === 'RPOS7') && !this.storeProfileDetails.locationName) || (this.storeProfileDetails.baseProduct === 'DE' && this.storeProfileDetails.organization.length === 0));
    }

    validateContactDetails() {
        return !((!this.storeProfileDetails.contactName && this.storeProfileDetailsService.mandatoryField('contactName')) || (!this.storeProfileDetails.contactEmail && this.storeProfileDetailsService.mandatoryField('contactEmail')) || (!this.storeProfileDetails.contactPhone && this.storeProfileDetailsService.mandatoryField('contactPhone'))
            || (!this.storeProfileDetails.contactMobile && this.storeProfileDetailsService.mandatoryField('contactMobile')));
    }

    validateShippingDetails() {
        if (this.checkOndc) {
            console.log(this.storeProfileDetails.deliveryDays, this.storeProfileDetails.deliveryDays.split(':').filter(i => ((i === '0') || (i === '00'))).length);
            const deliveryDaysLen = this.storeProfileDetails.deliveryDays.split(':').filter(i => ((i === '0') || (i === '00'))).length === 3;
            console.log(deliveryDaysLen);
            return !((deliveryDaysLen && this.storeProfileDetailsService.mandatoryField('deliveryDays')) || (this.isPanServiceable.value.toLowerCase() === 'delivery in radius' && !this.storeProfileDetails.radius && this.storeProfileDetailsService.mandatoryField('radius')) ||
                (!this.storeProfileDetails.orderFulfilment && this.storeProfileDetailsService.mandatoryField('orderFulfilment') || (this.storeProfileDetails.orderFulfilment.toLowerCase() === 'logistics shipping' && !this.logisticsDeliveryType.value && this.storeProfileDetailsService.mandatoryField('logisticsDeliveryType'))
                    || (this.storeProfileDetails.orderFulfilment.toLowerCase() === 'standard delivery' && !this.standardDeliveryType.value && this.storeProfileDetailsService.mandatoryField('standardDeliveryType'))));
        } else {
            return true;
        }
    }

    validateAdditionalDetails() {
        return !((!this.storeProfileDetails.longDescription && this.storeProfileDetailsService.mandatoryField('longDescription')) || (!this.storeProfileDetails.currency  && this.storeProfileDetailsService.mandatoryField('currency')) || (!this.storeProfileDetails.languageType && this.storeProfileDetailsService.mandatoryField('languageType')) );
    }

    validateWorkingHours() {
        if (this.checkOndc) {
            if (this.storeProfileDetails.isFixedTiming) { // Fixed
                const startTime = moment(this.storeProfileDetails.shopOpensAt === '00:00' ? '24:00' : this.storeProfileDetails.shopOpensAt, 'HH:MM:ss');
                const endTime = moment(this.storeProfileDetails.shopOpensAt === '00:00' ? '24:00' : this.storeProfileDetails.shopOpensAt, 'HH:MM:ss');
                if (this.storeProfileDetails.shopOpensAt === this.storeProfileDetails.shopClosesAt) {
                    this.notifyService.showWarn('Warn', 'Start Time and End Time should not be same');
                    return false;
                } else if (startTime.diff(endTime)) {
                    this.notifyService.showWarn('Warn', 'Kindly Enter Valid Open and Close Time');
                    return false;
                } else {
                    return true;
                }
            } else { // Split
                if (!this.storeProfileDetails.servicableFrequency) {
                    this.notifyService.showWarn('Warn', 'Kindly Enter Schedule Timing');
                    return false;
                } if (+this.storeProfileDetails.servicableFrequency > 24) {
                    this.notifyService.showWarn('Warn', 'Schedule working hours should be less than or equal to 24 hours ');
                    return false;
                } else if (!this.storeProfileDetails.splitTime.length) {
                    this.notifyService.showWarn('Warn', 'Kindly Add Starting Timing');
                    return false;
                }
                return true;
            }
        } else {
            return true;
        }
    }

    postStoreDetails() {
       if (!this.validateProfileDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Shop Details');
       } else if (!this.validateBankDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Bank Details');
       } else if (!this.validatePosDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In POS Details');
       } else if (!this.validateAddressDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Address Details');
       } else if (!this.validateContactDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Contact Details');
       } else if (!this.validateWorkingHours()) {
           return;
       } else if (!this.validateShippingDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Shipping Details');
       } else if (!this.validateAdditionalDetails()) {
           this.notifyService.showWarn('Warn', 'Kindly Fill All Mandatory Fields In Additional Details');
       } else if (this.checkOndc && this.storeProfileDetails.fssaiNumber && this.storeProfileDetails.fssaiNumber.length !== 14) {
           this.notifyService.showWarn('Warn', 'FSSAI License No should have 14 digits');
       } else {
       const body = {...this.storeProfileDetails};
       body['userId'] = String(this.authService.getServiceUserId());
       body['vendor'] = this.authService.getSkewName();
       body['outletId'] = !!this.selectedoutletId ? (this.selectedoutletId) : this.isPOS ? this.storeProfileDetails.outletId : 0;
       body['userName'] = this.authService.getUserName();
       body['customerId'] = this.authService.getCustomerId();
       body['posUrl'] = this.authService.isStandalone() || this.isThirdParty || this.isFoodAggregatorStore ? 'StandAlone' : window.btoa(body['posUrl']);
       body['apiUser'] = this.authService.getSkewName();
       body['skewCode'] = this.authService.getSkewCode();
           body['otp'] = '453452';
           body['posLocationId'] = Number(this.storeProfileDetails.posLocationId);
           body['locationName'] = this.isPOS ? this.filterName(this.storeProfileDetails.posLocationId) : '';
           body['orderNo'] = this.isPOS ? this.storeProfileDetails.orderNo : '';
           if (this.authService.getSkewCode() === '926') {
               body['vertical'] = 'RESTAURANT';
           }
           if (this.storeProfileDetails.vertical === 'RESTAURANT') {
               if (this.storeProfileDetails.orderFulfilment === 'Logistics Shipping') {
                   body['deliveryType'] = 'Immediate Delivery';
               } else {
                   body['deliveryType'] = '';
               }
           } else if (this.logisticsDeliveryType.value === 'Standard Delivery' && this.standardDeliveryType.value === 'Same Day Delivery') {
               body['deliveryType'] = 'Same Day Delivery';
           } else if (this.logisticsDeliveryType.value === 'Standard Delivery' && this.standardDeliveryType.value === 'Next Day Delivery') {
               body['deliveryType'] = 'Next Day Delivery';
           } else if (this.logisticsDeliveryType.value === 'Standard Delivery' && this.standardDeliveryType.value === 'Both') {
               body['deliveryType'] = 'Standard Delivery';
           } else {
               body['deliveryType'] = this.logisticsDeliveryType.value;
           }
           if (this.timeToShipIsoFormat) {
             body['deliveryDays'] = this.timeToShipIsoFormat;
           }
           body['timeFormat'] = 'days:hrs:min';
           if (this.storeProfileDetails.radius) {
               body['radius'] = Number(this.storeProfileDetails.radius);
           }
           this.storeProfileDetails.isPanServiceable = this.isPanServiceable.value === 'Delivery in Radius';
           if (this.storeProfileDetails.baseProduct === 'RPOS6' || this.storeProfileDetails.baseProduct === 'RPOS7' || this.storeProfileDetails.baseProduct === 'DE') {
               body['domain'] = 'WebReporter';
           } else if (this.storeProfileDetails.baseProduct === 'HQ') {
               body['domain'] = 'RayMedi_HQ';
           } else if (this.storeProfileDetails.baseProduct && this.storeProfileDetails.baseProduct.toLowerCase() === 'servquick') {
               body['domain'] = 'servquick';
           } else {
               body['domain'] = this.storeProfileDetails.baseProduct;
           }
           if (this.storeProfileDetails.baseProduct !== 'DE') {
              body['organization'] = [];
           } else if (this.storeProfileDetails.baseProduct === 'DE') {
               body['organization'] = [this.storeProfileDetails.organization];
           }
            body['socialMedia'] =  this.storeProfileDetails.socialMedia.length ? this.storeProfileDetails.socialMedia : this.previousSocialMedia.length && !this.storeProfileDetails.socialMedia.length ?  [{'id': this.storeProfileDetails.posId, 'socialMediaType': this.previousSocialMedia[0].socialMediaType, 'socialMediaLink': 'none'}] : [];
        this.ecommerceService.postStoreProfileDetails(body).subscribe(
            res => {
                if (res && res.status) {
                    this.notifyService.showSuccess('Success', res.message);
                    if (res.outletId) {
                        this.selectedoutletId = res.outletId;
                    }
                    this.updateVertical(body['vertical']);
                    this.getStoreDetails();
                    this.sharedService.setMenuReload(true);
                } else {
                    this.notifyService.showWarn('Warn', res.message);
                }
            },
            error => {
                this.notifyService.showWarn('Warn', error);
            }
        );
       }
    }

    updateVertical(vertical: string) {
        if (this.authService.getVerticalName() !== vertical) {
            this.authService.setVerticalName(vertical);
        }
    }

    updateStoreTiming() {
        this.storeProfileDetails.isFixedTiming = this.selectedStoreTiming === 'Fixed';
    }

    resetOutletDetails() {
        this.getStoreDetails();
    }

    closeModal(type) {
        this[type] = false;
        if (type === 'isDaysModalOpen') {
            this.stdCodes = {
                state: {
                    value: '',
                    isOpen: false,
                },
                stateList: this.stdCodes.stateList,
                city: {
                    value: '',
                    isOpen: false
                },
                cityList: [],
                stdCode: ''
            };
        }
    }

    openModal(type, value?) {
        console.log((type === 'isDaysModalOpen' && !this.checkOndc), type, this.checkOndc);
        if (type === 'isDaysModalOpen' && !this.checkOndc) {
            return;
        }
        this[type] = true;
        // value.storeAvailability = this.selectedDay.storeAvailability;
        if (type === 'imageModal') {
            this.selectedImage = value;
            return;
        }
    }

    updateWorkingHour() {
        this.isDaysModalOpen = false;
    }

    updateDay(day) {
        if (!this.selectedDay.storeAvailability || this.selectedDay.storeAvailability === '0') {
            if (this.selectedDay.shopOpensAt === '00:00' || this.selectedDay.shopClosesAt === '00:00') {
                this.notifyService.showWarn('Warn', 'Kindly Enter the time value');
                return;
            } else if (Number(this.selectedDay.shopOpensAt.split(':')[0]) > Number(this.selectedDay.shopClosesAt.split(':')[0])) {
                this.notifyService.showWarn('Warn', 'Closing time should be after opening time');
                return;
            }
        }
        if (!(!!this.selectedDay.storeAvailability && this.selectedDay.storeAvailability !== '0')) {
            this.selectedDay.storeAvailability = '';
            const openValue = this.selectedDay.shopOpensAt.split(':');
            const closeValue = this.selectedDay.shopClosesAt.split(':');
            openValue[0] = Number(openValue[0]) > 12 ? (Number(openValue[0]) - 12) + '' : openValue[0];
            closeValue[0] = Number(closeValue[0]) > 12 ? (Number(closeValue[0]) - 12) + '' : closeValue[0];
            this.selectedDay.shopOpensAt12hrs = openValue.join(':') + (Number(this.selectedDay.shopOpensAt.split(':')[0]) > 12 ? ' PM' : ' AM');
            this.selectedDay.shopClosesAt12hrs = closeValue.join(':') + (Number(this.selectedDay.shopClosesAt.split(':')[0]) > 12 ? ' PM' : ' AM');
        }
        if (this.applyForAll) {
            this.applyForAllDays();
            this.applyForAll = false;
        }
        this.isDaysModalOpen = false;
    }

    applyForAllDays() {
        this.storeProfileDetails.availabilityDays.forEach(day => {
            day['shopClosesAt'] = this.selectedDay.shopClosesAt;
            day['shopOpensAt'] = this.selectedDay.shopOpensAt;
            day['shopOpensAt12hrs'] = this.selectedDay.shopOpensAt12hrs;
            day['shopClosesAt12hrs'] = this.selectedDay.shopClosesAt12hrs;
            day['storeAvailability'] = this.selectedDay.storeAvailability;
        });
    }

    isSelected(day: string) {
        return this.selectedDays.includes(day);
    }

    onChange(type) {
        if (type === this.selectedDay.storeAvailability) {
            this.selectedDay.storeAvailability = '';
        } else {
            this.selectedDay.storeAvailability = type;
        }
    }

    splitHoursTime(event) {
    }

    setDefaultDays() {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        this.storeProfileDetails.availabilityDays = days.reduce((resultArray, d) => {
            const found = resultArray.find(a => a === d);
            if (!found) {
                resultArray.push({
                    'day' : d,
                    'storeAvailability' : 'Opened'
                });
            }
            return resultArray;
        }, []);
    }

    getStoreDetails() {
        const body = {
            'userId': this.authService.getServiceUserId(),
            'vendor': this.authService.getSkewName(),
            'outletId': this.selectedoutletId ? this.selectedoutletId : ''
        };
        if (this.authService.getSkewCode() === '910') {
            body['storeUserId'] = this.storeUserId;
        }
        this.ecommerceService.getStoreProfileDetails(body).subscribe(
            res => {
                this.setDefaultDays();
                if (res && res.length) {
                    this.storeProfileDetails = {...this.storeProfileDetails, ...res[0]};
                    this.previousSocialMedia = [...this.storeProfileDetails.socialMedia];
                    this.shopName.emit(this.storeProfileDetails.shopName);
                    this.storeProfileDetails.outletName = this.storeProfileDetails.shopName;
                    this.storeProfileDetails.availability = this.storeProfileDetails.availability ? this.storeProfileDetails.availability : 'Active';
                    this.isPanServiceable.value = this.storeProfileDetails.isPanServiceable ? 'Pan India delivery' : 'Delivery in Radius';
                    this.selectedStoreTiming = this.storeProfileDetails.isFixedTiming ? 'Fixed' : 'Split';
                    if (this.storeProfileDetails.vertical === 'RESTAURANT') {
                        this.storeProfileDetails.deliveryType = '';
                    } else if (this.storeProfileDetails.deliveryType === 'Standard Delivery') {
                        this.standardDeliveryType.value = 'Both';
                        this.logisticsDeliveryType.value = 'Standard Delivery';
                    } else if (this.storeProfileDetails.deliveryType === 'Next Day Delivery') {
                        this.standardDeliveryType.value = 'Next Day Delivery';
                        this.logisticsDeliveryType.value = 'Standard Delivery';
                    } else if (this.storeProfileDetails.deliveryType === 'Same Day Delivery') {
                        this.standardDeliveryType.value = 'Same Day Delivery';
                        this.logisticsDeliveryType.value = 'Standard Delivery';
                    } else {
                        this.standardDeliveryType.value = 'Same Day Delivery';
                        this.logisticsDeliveryType.value = this.storeProfileDetails.deliveryType;
                    }
                    if (!this.storeProfileDetails.availabilityDays.length) {
                        this.setDefaultDays();
                    }
                    if (this.storeProfileDetails.languageType) {
                        const filteredName = this.languageType.list.filter(data => data.code == this.storeProfileDetails.languageType);
                        if (filteredName.length) {
                           this.languageType.value = filteredName[0].name;
                        }
                    }
                    if (this.storeProfileDetails.deliveryDays) {
                        let parseTimeToShip = this.storeProfileDetails.deliveryDays.split(':');
                        console.log(parseTimeToShip);
                        if (this.storeProfileDetails.vertical === 'RESTAURANT') {
                            this.timeToShip.days = '00';
                            this.durationObj.days = 0;
                        } else {
                            this.timeToShip.days = parseTimeToShip[0];
                            this.durationObj.days = +parseTimeToShip[0];
                        }
                        this.timeToShip.hours = parseTimeToShip[1];
                        this.durationObj.hours = +parseTimeToShip[1];
                        this.timeToShip.minutes = parseTimeToShip[2];
                        this.durationObj.minutes = +parseTimeToShip[2];
                    }
                    this.timeToShipIsoFormat = `${this.durationObj.days}:${this.durationObj.hours}:${this.durationObj.minutes}`;
                    if (this.storeProfileDetails.splitTime.length) {
                        this.workingHours24Format.list.forEach((time) => {
                            this.storeProfileDetails.splitTime.forEach((serviceTime) => {
                                if (time.shopOpensAt === serviceTime.shopOpensAt) {
                                    time.id = serviceTime.id;
                                    serviceTime['value'] = time.value;
                                }
                            });
                        });
                        this.removeElements(this.storeProfileDetails.splitTime);
                    }
                    if (this.storeProfileDetails.organization.length) {
                        this.storeProfileDetails.organization = this.storeProfileDetails.organization[0];
                    }
                    if (this.storeProfileDetails.currency) {
                        if (this.checkOndc) {
                            const value = this.currency.list.filter(cur => {
                                return cur.id === decodeURIComponent(this.storeProfileDetails.currency);
                            });
                            this.currency.value = value && value.length ? value[0].name : this.currency.value;
                        } else {
                            this.currency.value = decodeURIComponent(this.storeProfileDetails.currency);
                        }
                    } else {
                        this.optionChange('currency', this.currency.list[0]);
                    }
                    if (Number(res[0].longitude) && Number(res[0].latitude)) {
                        this.storeProfileDetails.latitude = +res[0].latitude;
                        this.storeProfileDetails.longitude = +res[0].longitude;
                    }
                    this.setCurrentLocation();
                    this.defaultProfileDetails = {...this.storeProfileDetails};
                }
            },
            error => {
                this.notifyService.showWarn('Warn', error.message);
            }
        );
    }

    openCalender() {
        document.getElementById('matButtonCalendar').click();
    }

    async onImageLoad(image) {
        return new Promise(resolve => {
            this.uiCommonService.showLoader('blocking');
            const fr = new FileReader;
            if ((Number(image.size) / 1024) > 5000) {
                resolve(true);
            } else {
                fr.onload = () => {
                    const img = new Image;
                    img.onload = () => {
                        if (img.width < 100 || img.height < 100) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }

                    };
                    img.src = fr.result as any;
                };
                fr.readAsDataURL(image);
            }
        });
    }

    async onUploadImage(image: FileList, event, type: string) {
        // if (this.imageEditableFlag === 'false') {
        //   this.notifyService.showWarn('Warning', 'Can\'t ADD image As \'Sync Image from POS\' is enabled. Please disable & try ');
        //   return;
        // }
        const fileType = image[0].type;
        if (fileType.match(/image\/*/) === null) {
            this.uiCommonService.hideLoader();
            this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
            return;
        }
        if (fileType === 'image/gif') {
            this.uiCommonService.hideLoader();
            this.notifyService.showWarn('Warning', 'Only jpg, jpeg and png files are allowed.');
            return;
        }
        if (await this.onImageLoad(image[0])) {
            this.uiCommonService.hideLoader();
            this.notifyService.showWarn('Warn', 'Please Upload Image of dimensions minimum 100 X 100 and less than 5MB');
            return;
        }
        const base64Image: any = await this.imageCompressService.compressImage(image[0]);
        this.uiCommonService.hideLoader();
        await this.uploadImage(base64Image, type);
    }

    async uploadImage(base64Image, type) {
        const postJson = {
            'userId': '' + this.authService.getServiceUserId(),
            'vendor': this.authService.getSkewName(),
            'outletId': this.authService.getOutlet(),
            'image': base64Image,
        };

        this.ecommerceService.uploadImg(postJson).subscribe(
            (data) => {
                if (data && data.status) {
                    // this.notifyService.showSuccess('Success', data.message);
                    if (type === 'add' || type === 'replace') {
                        this.storeProfileDetails.shopImageUrl = data.refId;
                    } else if (type) {
                        this.storeProfileDetails[type].push(data.refId);
                    }
                }
            },
            (err) => {
                this.notifyService.showError('Error', err);
            });
    }

    onFilterChange(startTime, value) {
        console.log(startTime, value);
        const date = moment(value).format('YYYY-MM-DD');
        const index = this.storeProfileDetails.closingDays.indexOf(date);
        if (index === -1) {
            this.storeProfileDetails.closingDays.push(date);
        }
    }

    removeClosingDate(date, closingDayList) {
        this.selectedDate = '';
        const index = closingDayList.indexOf(date);
        if (index > -1) {
            closingDayList.splice(index, 1);
        }
    }

    returnRemoveValues = (index) => {
        const frequency = +this.storeProfileDetails.servicableFrequency;
        const start = (index - frequency) ? index - frequency : 0;
        const end = index + frequency;
        const keys = Array.from({length : 24}, (val, index) => index);
        return (keys.filter(x => (start < x && x < end)));
    }

    removeValueFromArray = (arrayList, removeList) => {
        return arrayList.filter(x => (!removeList.some((remove) => remove === x.value)));
    }
    removeElements = (selectedArray) => {
        this.timeList = [...this.workingHours24Format.list];
        let removeArray = [];
        selectedArray.forEach(arr => {
            removeArray = [...removeArray, ...this.returnRemoveValues(arr.value)];
        });
        this.timeList = [...this.removeValueFromArray([...this.timeList], removeArray), ...selectedArray].sort((a, b) => (a.value - b.value));
    }

    addSplitTime(time) {
        const index = this.storeProfileDetails.splitTime.findIndex(el => el.shopOpensAt === time.shopOpensAt);
        if (index === -1) {
            this.storeProfileDetails.splitTime.push(time);
        } else {
            this.storeProfileDetails.splitTime.splice(index, 1);
        }
       this.removeElements(this.storeProfileDetails.splitTime);
    }

    copyTextClipBoard(data, type) {
        this[type] = true;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = data;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this[type] = false;
        }, 2500);
    }

    optionCheckedChange(day: any, event: any) {
        day.storeAvailability = event.checked ? 'Opened' : 'Closed';
    }

    validateInputNumber(event: any) {
        const inp = String.fromCharCode(event.keyCode);
        if ((/[0-9]/.test(inp))) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    validateInputNumberLength(event: any) {
        const inp = String.fromCharCode(event.keyCode);
        if ((/[0-9]/.test(inp)) && event.target.value.length < 2) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    updateFrequency(event) {
        this.validateInputNumberLength(event);
        this.storeProfileDetails.splitTime = [];
        this.removeElements(this.storeProfileDetails.splitTime);
    }
    public checkChanges() {
        this.defaultProfileDetails.latitude = this.storeProfileDetails.latitude;
        this.defaultProfileDetails.longitude = this.storeProfileDetails.longitude;
        return (JSON.stringify(this.storeProfileDetails) === JSON.stringify(this.defaultProfileDetails));
    }

    public testUrl() {
        if (this.storeProfileDetails.posUrl === null) {
            this.notifyService.showWarn('Warning', 'Domain URL field is Empty');
        } else if (this.storeProfileDetails.baseProduct) {
            const product = this.storeProfileDetails.baseProduct;
            if (product === 'RPOS6' || product === 'RPOS7' || product === 'DE') {
                this.storeProfileDetails.domain = 'WebReporter';
            } else if (product === 'HQ') {
                this.storeProfileDetails.domain = 'RayMedi_HQ';
            } else if (product === 'ServQuick') {
                this.storeProfileDetails.domain = 'servquick';
            } else {
                this.storeProfileDetails.domain = product;
            }

            this.ordereasyService.testURL(this.storeProfileDetails.posUrl, this.storeProfileDetails.domain).subscribe(
                msg => {
                    this.notifyService.showInfo('Info', msg['response']);
                },
                (error) => {
                    this.notifyService.showError('Error', 'Error while processing URL');
                },
                () => {
                }
            );
        } else {
            this.notifyService.showWarn('Warning', 'Kindly Choose Product');
        }
    }

    validateTimeToShip(value, type) {
        switch (type) {
            case 'days': {
                value = value < 5 ? value : 5;
                break;
            }
            case 'hours': {
                value = value < 23 ? value : 23;
                break;
            }
            case 'minutes': {
                value = value < 59 ? value : 59;
                break;
            }
        }
        this.timeToShip[type] = String(value).padStart(2, '0').slice(-2);
        if (this.storeProfileDetails.vertical === 'RESTAURANT') {
            this.timeToShip.days = '00';
            this.durationObj.days = 0;
        } else {
            this.durationObj.days = +this.timeToShip.days;
        }
            this.durationObj.hours = +this.timeToShip.hours,
            this.durationObj.minutes = +this.timeToShip.minutes,
            this.timeToShipIsoFormat = `${this.durationObj.days}:${this.durationObj.hours}:${this.durationObj.minutes}`.replace(/NaN/g, '00');
        this.storeProfileDetails.deliveryDays = this.timeToShipIsoFormat;
    }

}
