import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {EcommerceService} from '../../modules/integration/ecommerce/ecommerce.service';
import {NotificationService} from '../services/notification.service';
import {SharedService} from '../../shared/service/shared.service';
import {LoginService} from '../../modules/login/login.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private ecommerceService: EcommerceService,
    private notificationService: NotificationService,
    private cookieService: CookieService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (state.url.includes('cpp')) {
        return true;
    }
    if (window.location.pathname === '/Integration/ecommerce/liscense' && state.url === '/Integration/ecommerce/liscense') {
      this.router.navigate(['/login']);
      this.cookieService.deleteAll();
      sessionStorage.clear();
      return false;
    }
    if ((this.authenticationService.isStoreProfileAvail()) && !(this.sharedService.getOpenModalValue() && this.sharedService.getOpenModalValue().expired) && state.url !== this.getRedirectLink(this.authenticationService.getSkewCode())) {
      const body = {
        'userId' : this.authenticationService.getServiceUserId(),
        'vendor' : this.authenticationService.getSkewName(),
        'outletId' : '0',
      };
      this.ecommerceService.getStoreOutletListDetails(body).subscribe(
          res => {
            if (!(res && res.length)) {
              if (this.authenticationService.getMultiAddonDetails('936')) {
                this.router.navigate(['/Integration/ecommerce/ordereasy/api-integration-setup']);
              } else {
                this.notificationService.showWarn('Warn', 'Kindly Setup Profile and Proceed Further');
                this.router.navigate([this.getRedirectLink(this.authenticationService.getSkewCode())]);
              }
            } else if (this.authenticationService.getSkewCode() === '1003') {
              this.checkPolicyStatus();
            }
          }
      );
      return this.authenticationService.isLoggedIn();
    } else if (state.url === this.getRedirectLink(this.authenticationService.getSkewCode())) {
      if ( this.authenticationService.getMultiAddonDetails('936')) {
        const body = {
          'userId' : this.authenticationService.getServiceUserId(),
          'vendor' : this.authenticationService.getSkewName(),
          'outletId' : '0',
        };
        console.log('inside')  ;
        this.ecommerceService.getStoreOutletListDetails(body).subscribe(
            res => {
              if (!(res && res.length)) {
                if (this.authenticationService.getMultiAddonDetails('936')) {
                  this.router.navigate(['/Integration/ecommerce/ordereasy/api-integration-setup']);
                }
              }
            }
        );
      } else if (this.authenticationService.getSkewCode() === '1003') {
        this.checkPolicyStatus();
      }
      return this.authenticationService.isLoggedIn();
    }
    if (this.authenticationService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }
checkPolicyStatus() {
  if ((!this.sharedService.getOpenModalValue() || (this.sharedService.getOpenModalValue() && !this.sharedService.getOpenModalValue().expired)) && this.authenticationService.getSkewCode() === '1003') {
    this.ecommerceService.checkPolicyApprovedOrNot().subscribe(
        res => {
          if (res && res.privacyPolicyStatus  === 'pending' && window.location.pathname !== '/Integration/ecommerce/privacy-policy') {
            this.router.navigate( ['/Integration/ecommerce/privacy-policy']);
          }
        }
    );
  }
}
  getRedirectLink(skewCode) {
    switch (skewCode) {
      case  '917' :
        return '/Integration/ecommerce/config/ordereasy-store-profile';
      case '923':
        return '/Integration/ecommerce/config/ordereasy-store-profile';
      case '1003':
        return '/Integration/ecommerce/market-place';
      case '937':
        return '/Integration/ecommerce/market-place';
      case '910':
        return '/Integration/store-aggregator/store-aggregator-profile';
      default:
        return '/Integration/ecommerce/config/ordereasy-store-profile';
    }
  }
}
